<template>
    <v-row no-gutters justify="center">
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <template v-if="!isViewReady">
                <v-row style="height: 100%" align="center" justify="center">
                    <div class="app-splash-loader"></div>
                </v-row>
            </template>
            <template v-if="errorNotFound">
                <!-- <p class="text-h5 font-weight-light red--text">The link was not found.</p> -->
                <v-row justify="center" class="py-5 mt-8">
                    <v-col>
                        <h1 class="text-h4 font-weight-light text-center">Not Found</h1>
                        <p class="text-center mt-10">The resource you requested is not available.</p>
                    </v-col>
                </v-row>
            </template>
            <template v-if="errorForbidden">
                <v-row justify="center" class="py-5 mt-8">
                    <v-col>
                        <h1 class="text-h4 font-weight-light text-center">Forbidden</h1>
                        <p class="text-center mt-10">The resource you requested is not available.</p>
                    </v-col>
                </v-row>
            </template>
            <template v-if="errorUnavailable">
                <v-row justify="center" class="py-5 mt-8">
                    <v-col>
                        <h1 class="text-h4 font-weight-light text-center">Server Error</h1>
                        <p class="text-center mt-10">The resource you requested is temporarily unavailable. Please try again later.</p>
                    </v-col>
                </v-row>
            </template>
        </v-col>
    </v-row>
</template>
<style lang="css">
.app-splash-loader {
    border: 4px solid #e1bee7; /* purple lighten-4 */
    border-top: 4px solid #9c27b0; /* purple */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1.0s linear infinite;
    margin: auto;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
<script>
// import { mapState /* , mapGetters */ } from 'vuex';
// import { client } from '@/client';

export default {
    components: {
    },
    data: () => ({
        isViewReady: false,
        interactionId: null,
        // loading: true,
        errorNotFound: false,
        errorForbidden: false,
        errorUnavailable: false,
    }),
    computed: {
        // ...mapState({
        //     isAuthenticatedReady: (state) => state.isReady,
        //     session: (state) => state.session,
        // }),
        // isAuthenticated() {
        //     return this.session.isAuthenticated;
        // },
    },
    methods: {
        async init() {
            // console.log('interaction.vue: init; isAuthenticated: %o', this.isAuthenticated);
            if (this.$route.params.inviteId) {
                await this.start(this.$route.params.inviteId);
            } else {
                this.errorNotFound = true;
            }
            this.isViewReady = true;
        },
        async start(inviteId) {
            try {
                this.$store.commit('loading', { start: true });
                const response = await this.$client.main().invite.check({ id: inviteId });
                console.log(`start response: ${JSON.stringify(response)}`);
                const { type, /* interaction_id: interactionId, */ next_route: nextRoute, error } = response;
                console.log(`start, invite type ${type}`);
                if (error) {
                    this.errorNotFound = true;
                } else if (nextRoute) {
                    console.log(`start nextRoute: ${JSON.stringify(nextRoute)}`);
                    this.$router.replace(nextRoute);
                } else if (type === 'form-entry') {
                    this.$router.replace({ name: 'form', params: { inviteId } });
                    /*
                    this.interactionId = interactionId;
                    // TODO: load the iteraction?
                    console.log(`got interaction id: ${interactionId}`);
                    this.loadInteraction();
                    */
                } else {
                    console.error(`unknown invite type ${type}`);
                    this.errorNotFound = true;
                }
            } catch (err) {
                if (err.response?.status === 403) {
                    console.log('invite not found or access is forbidden');
                    this.errorNotFound = true; // we show a forbidden error as not found, because we really don't know if the invite wasn't found or if the user is forbidden from accessing it (different than unauthorized)
                } else if (err.response?.status === 404 || err.response?.status === 500) {
                    // 404 error from server means the API wasn't found, which should hopefully be a temporary situation during an upgrade; the API itself never returns 404, it's always 400 (if we didn't send invite id), 401 (if login is required), 403 (for invite not found or not authorized for this user), or 500 (if an exception occurred).
                    console.log('server error');
                    this.errorUnavailable = true;
                } else {
                    console.error('failed to load invitation', err);
                }
            } finally {
                this.$store.commit('loading', { start: false });
            }
        },
        /*
        async loadInteraction() {
            try {
                this.$store.commit('loading', { loadInteraction: true });
                const response = await this.$client.interaction(this.interactionId).get();
                console.log(`loadInteraction response: ${JSON.stringify(response)}`);
                const { type, next, state } = response;
                console.log(`interaction.vue: loadInteraction: interaction type: ${type} next: ${next} state ${JSON.stringify(state)}`);
                if (type === 'entry') {
                    this.$router.replace({ name: 'form', query: { i: this.interactionId } });
                }
                // TODO: go to form entry , if it's an entry type and it's not been completed yet ....
                // switch (type) {
                // case 'create_account':
                //     this.createAccount({ type, next, state }, prevInteraction);
                //     break;
                // default:
                //     console.error('interaction.vue: unknown interaction type: %s', type);
                //     this.interactionError = true;
                //     break;
                // }
            } catch (err) {
                console.error('failed to load interaction', err);
            } finally {
                this.$store.commit('loading', { loadInteraction: false });
            }
        },
        */
    },
    mounted() {
        console.log('invite.vue: mounted');
        this.init();
    },
};
</script>
